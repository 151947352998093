<ng-container *ngFor="let banner of banners$ | async">
  <bit-banner
    *ngIf="banner.visible"
    bannerType="warning"
    (onClose)="closeBanner(banner.organizationId)"
  >
    {{ "maintainYourSubscription" | i18n: banner.organizationName }}
    <a
      bitLink
      linkType="contrast"
      [routerLink]="['/organizations', banner.organizationId, 'billing', 'payment-method']"
      >{{ "addAPaymentMethod" | i18n }}</a
    >.
  </bit-banner>
</ng-container>

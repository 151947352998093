<app-header></app-header>

<bit-container>
  <p>
    {{ "emergencyAccessDesc" | i18n }}
    <a href="https://bitwarden.com/help/emergency-access/" target="_blank" rel="noreferrer">
      {{ "learnMore" | i18n }}.
    </a>
  </p>

  <p *ngIf="isOrganizationOwner">
    <b>{{ "warning" | i18n }}:</b> {{ "emergencyAccessOwnerWarning" | i18n }}
  </p>

  <div class="page-header d-flex">
    <h2>
      {{ "trustedEmergencyContacts" | i18n }}
      <app-premium-badge></app-premium-badge>
    </h2>
    <div class="ml-auto d-flex">
      <button
        class="btn btn-sm btn-outline-primary ml-3"
        type="button"
        (click)="invite()"
        [disabled]="!canAccessPremium"
      >
        <i aria-hidden="true" class="bwi bwi-plus bwi-fw"></i>
        {{ "addEmergencyContact" | i18n }}
      </button>
    </div>
  </div>

  <table
    class="table table-hover table-list mb-0"
    *ngIf="trustedContacts && trustedContacts.length"
  >
    <tbody>
      <tr *ngFor="let c of trustedContacts; let i = index">
        <td width="30">
          <bit-avatar
            [text]="c | userName"
            [id]="c.granteeId"
            [color]="c.avatarColor"
            size="small"
          ></bit-avatar>
        </td>
        <td>
          <a href="#" appStopClick (click)="edit(c)">{{ c.email }}</a>
          <span
            bitBadge
            variant="secondary"
            *ngIf="c.status === emergencyAccessStatusType.Invited"
            >{{ "invited" | i18n }}</span
          >
          <span
            bitBadge
            variant="warning"
            *ngIf="c.status === emergencyAccessStatusType.Accepted"
            >{{ "accepted" | i18n }}</span
          >
          <span
            bitBadge
            variant="warning"
            *ngIf="c.status === emergencyAccessStatusType.RecoveryInitiated"
            >{{ "emergencyAccessRecoveryInitiated" | i18n }}</span
          >
          <span bitBadge *ngIf="c.status === emergencyAccessStatusType.RecoveryApproved">{{
            "emergencyAccessRecoveryApproved" | i18n
          }}</span>

          <span bitBadge *ngIf="c.type === emergencyAccessType.View">{{ "view" | i18n }}</span>
          <span bitBadge *ngIf="c.type === emergencyAccessType.Takeover">{{
            "takeover" | i18n
          }}</span>

          <small class="text-muted d-block" *ngIf="c.name">{{ c.name }}</small>
        </td>
        <td class="table-list-options">
          <button
            [bitMenuTriggerFor]="trustedContactOptions"
            class="tw-border-none tw-bg-transparent tw-text-main"
            type="button"
            appA11yTitle="{{ 'options' | i18n }}"
          >
            <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
          </button>
          <bit-menu #trustedContactOptions>
            <button
              type="button"
              bitMenuItem
              *ngIf="c.status === emergencyAccessStatusType.Invited"
              (click)="reinvite(c)"
            >
              <i class="bwi bwi-fw bwi-envelope" aria-hidden="true"></i>
              {{ "resendInvitation" | i18n }}
            </button>
            <button
              type="button"
              bitMenuItem
              *ngIf="c.status === emergencyAccessStatusType.Accepted"
              (click)="confirm(c)"
            >
              <i class="bwi bwi-fw bwi-check" aria-hidden="true"></i>
              {{ "confirm" | i18n }}
            </button>
            <button
              type="button"
              bitMenuItem
              *ngIf="c.status === emergencyAccessStatusType.RecoveryInitiated"
              (click)="approve(c)"
            >
              <i class="bwi bwi-fw bwi-check" aria-hidden="true"></i>
              {{ "approve" | i18n }}
            </button>
            <button
              type="button"
              bitMenuItem
              *ngIf="
                c.status === emergencyAccessStatusType.RecoveryInitiated ||
                c.status === emergencyAccessStatusType.RecoveryApproved
              "
              (click)="reject(c)"
            >
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "reject" | i18n }}
            </button>
            <button type="button" bitMenuItem (click)="remove(c)">
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "remove" | i18n }}
            </button>
          </bit-menu>
        </td>
      </tr>
    </tbody>
  </table>

  <ng-container *ngIf="!trustedContacts || !trustedContacts.length">
    <p *ngIf="loaded">{{ "noTrustedContacts" | i18n }}</p>
    <ng-container *ngIf="!loaded">
      <i
        class="bwi bwi-spinner bwi-spin text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="sr-only">{{ "loading" | i18n }}</span>
    </ng-container>
  </ng-container>

  <div class="page-header spaced-header">
    <h2>{{ "designatedEmergencyContacts" | i18n }}</h2>
  </div>

  <table
    class="table table-hover table-list mb-0"
    *ngIf="grantedContacts && grantedContacts.length"
  >
    <tbody>
      <tr *ngFor="let c of grantedContacts; let i = index">
        <td width="30">
          <bit-avatar
            [text]="c | userName"
            [id]="c.grantorId"
            [color]="c.avatarColor"
            size="small"
          ></bit-avatar>
        </td>
        <td>
          <span>{{ c.email }}</span>
          <span bitBadge *ngIf="c.status === emergencyAccessStatusType.Invited">{{
            "invited" | i18n
          }}</span>
          <span
            bitBadge
            variant="warning"
            *ngIf="c.status === emergencyAccessStatusType.Accepted"
            >{{ "accepted" | i18n }}</span
          >
          <span
            bitBadge
            variant="warning"
            *ngIf="c.status === emergencyAccessStatusType.RecoveryInitiated"
            >{{ "emergencyAccessRecoveryInitiated" | i18n }}</span
          >
          <span
            bitBadge
            variant="success"
            *ngIf="c.status === emergencyAccessStatusType.RecoveryApproved"
            >{{ "emergencyAccessRecoveryApproved" | i18n }}</span
          >

          <span bitBadge *ngIf="c.type === emergencyAccessType.View">{{ "view" | i18n }}</span>
          <span bitBadge *ngIf="c.type === emergencyAccessType.Takeover">{{
            "takeover" | i18n
          }}</span>

          <small class="text-muted d-block" *ngIf="c.name">{{ c.name }}</small>
        </td>
        <td class="table-list-options">
          <button
            [bitMenuTriggerFor]="grantedContactOptions"
            class="tw-border-none tw-bg-transparent tw-text-main"
            type="button"
            appA11yTitle="{{ 'options' | i18n }}"
          >
            <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
          </button>
          <bit-menu #grantedContactOptions>
            <button
              type="button"
              bitMenuItem
              *ngIf="c.status === emergencyAccessStatusType.Confirmed"
              (click)="requestAccess(c)"
            >
              <i class="bwi bwi-fw bwi-envelope" aria-hidden="true"></i>
              {{ "requestAccess" | i18n }}
            </button>
            <button
              type="button"
              bitMenuItem
              *ngIf="
                c.status === emergencyAccessStatusType.RecoveryApproved &&
                c.type === emergencyAccessType.Takeover
              "
              (click)="takeover(c)"
            >
              <i class="bwi bwi-fw bwi-key" aria-hidden="true"></i>
              {{ "takeover" | i18n }}
            </button>
            <button
              type="button"
              bitMenuItem
              *ngIf="
                c.status === emergencyAccessStatusType.RecoveryApproved &&
                c.type === emergencyAccessType.View
              "
              [routerLink]="c.id"
            >
              <i class="bwi bwi-fw bwi-eye" aria-hidden="true"></i>
              {{ "view" | i18n }}
            </button>
            <button type="button" bitMenuItem (click)="remove(c)">
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "remove" | i18n }}
            </button>
          </bit-menu>
        </td>
      </tr>
    </tbody>
  </table>

  <ng-container *ngIf="!grantedContacts || !grantedContacts.length">
    <p *ngIf="loaded">{{ "noGrantedAccess" | i18n }}</p>
    <ng-container *ngIf="!loaded">
      <i
        class="bwi bwi-spinner bwi-spin text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="sr-only">{{ "loading" | i18n }}</span>
    </ng-container>
  </ng-container>
</bit-container>

<ng-template #addEdit></ng-template>
<ng-template #takeoverTemplate></ng-template>
<ng-template #confirmTemplate></ng-template>

<bit-layout variant="secondary">
  <nav slot="sidebar" *ngIf="provider">
    <a routerLink="." class="tw-m-5 tw-mt-7 tw-block" [appA11yTitle]="'providerPortal' | i18n">
      <bit-icon [icon]="logo"></bit-icon>
    </a>

    <bit-nav-item icon="bwi-bank" [text]="'clients' | i18n" route="clients"></bit-nav-item>
    <bit-nav-group icon="bwi-sliders" [text]="'manage' | i18n" route="manage" *ngIf="showManageTab">
      <bit-nav-item
        [text]="'people' | i18n"
        route="manage/people"
        *ngIf="provider.canManageUsers"
      ></bit-nav-item>
      <bit-nav-item
        [text]="'eventLogs' | i18n"
        route="manage/events"
        *ngIf="provider.useEvents"
      ></bit-nav-item>
    </bit-nav-group>
    <bit-nav-item
      icon="bwi-cogs"
      [text]="'settings' | i18n"
      route="settings"
      *ngIf="showSettingsTab"
    ></bit-nav-item>
  </nav>
  <app-payment-method-banners
    *ngIf="false"
    class="-tw-m-6 tw-flex tw-flex-col tw-pb-6"
  ></app-payment-method-banners>
  <router-outlet></router-outlet>
</bit-layout>
